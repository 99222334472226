import _toConsumableArray from"@babel/runtime/helpers/toConsumableArray";import{useMemo}from'react';var range=function range(start,end){var length=end-start+1;return Array.from({length:length},function(_,idx){return idx+start;});};export var usePagination=function usePagination(_ref){var totalCount=_ref.totalCount,pageSize=_ref.pageSize,_ref$siblingCount=_ref.siblingCount,siblingCount=_ref$siblingCount===void 0?1:_ref$siblingCount,currentPage=_ref.currentPage;// eslint-disable-next-line consistent-return
var paginationRange=useMemo(function(){var totalPageCount=Math.ceil(totalCount/pageSize);// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
var totalPageNumbers=siblingCount+5;/*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */if(totalPageNumbers>=totalPageCount){return range(1,totalPageCount);}// Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
var leftSiblingIndex=Math.max(currentPage-siblingCount,1);var rightSiblingIndex=Math.min(currentPage+siblingCount,totalPageCount);var shouldShowLeftDots=leftSiblingIndex>2;var shouldShowRightDots=rightSiblingIndex<totalPageCount-2;var firstPageIndex=1;var lastPageIndex=totalPageCount;// Case 2: No left dots to show, but rights dots to be shown
if(!shouldShowLeftDots&&shouldShowRightDots){var leftItemCount=3+2*siblingCount;var leftRange=range(1,leftItemCount);return[].concat(_toConsumableArray(leftRange),['...',totalPageCount]);}// Case 3: No right dots to show, but left dots to be shown
if(shouldShowLeftDots&&!shouldShowRightDots){var rightItemCount=3+2*siblingCount;var rightRange=range(totalPageCount-rightItemCount+1,totalPageCount);return[firstPageIndex,'...'].concat(_toConsumableArray(rightRange));}// Case 4: Both left and right dots to be shown
if(shouldShowLeftDots&&shouldShowRightDots){var middleRange=range(leftSiblingIndex,rightSiblingIndex);return[firstPageIndex,'...'].concat(_toConsumableArray(middleRange),['...',lastPageIndex]);}},[totalCount,pageSize,siblingCount,currentPage]);return paginationRange;};